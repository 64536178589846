import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import Performer, { AccountRelationship } from '@account/Performer'
import {
	FormValidationComponent,
	ValidationMessages,
} from '@ui/Components/Form/FormValidation.Component'
import HttpError, { FormErrors } from '@ui/HttpError'
import {
	SelectItem,
	SelectValue,
} from '@ui/Components/Form/Select/Select.Component'
import { FormBuilder, Validators } from '@angular/forms'
import { InputValue } from '@ui/Components/Form/TextInput/TextInput.Component'
import { PerformerService } from '@account/Services/PerformerService'
import { MatSnackBar } from '@angular/material/snack-bar'

@Component({
	selector: 'performer-info',
	templateUrl: './PerformerInfo.Component.html',
})
export class PerformerInfoComponent
	extends FormValidationComponent
	implements OnInit, OnDestroy
{
	@Input() public performer: Performer

	public minsEditable = 0

	public editable = false

	public formErrors: FormErrors = {
		first_name: '',
		last_name: '',
		school: '',
		dob: '',
		gender: '',
		account_relationship: '',
	}

	public genderItems: SelectItem[] = [
		{
			value: 'female',
			label: 'Female',
		},
		{
			value: 'male',
			label: 'Male',
		},
		{
			value: 'n/a',
			label: 'Prefer not to say',
		},
	]

	public relationshipItems: SelectItem[] = [
		{
			value: 'parent',
			label: 'Parent',
		},
		{
			value: 'grandparent',
			label: 'Grandparent',
		},
		{
			value: 'guardian',
			label: 'Guardian',
		},
		{
			value: 'other',
			label: 'Other',
		},
	]

	protected validationMessages: ValidationMessages = {
		first_name: {
			required: 'First name is required',
		},
		last_name: {
			required: 'Last name is required',
		},
		school: {
			required: 'School is required',
		},
		dob: {
			required: 'Date of birth is required',
		},
		gender: {
			required: 'Gender is required',
		},
		account_relationship: {
			required: 'Relationship with performer is required',
		},
	}

	private interval: any

	constructor(
		protected formBuilder: FormBuilder,
		private readonly performerService: PerformerService,
		private readonly snackbar: MatSnackBar,
	) {
		super(formBuilder)
	}

	public ngOnInit() {
		super.ngOnInit()

		this.calculateMinsEditable()

		if (this.editable) {
			this.interval = setInterval(this.calculateMinsEditable, 60 * 1000)
		}
	}

	public ngOnDestroy() {
		if (this.interval) {
			clearInterval(this.interval)
		}
	}

	public updatePerformer = async (
		firstName: InputValue,
		lastName: InputValue,
		school: InputValue,
		dob: Date,
		gender: SelectValue,
		relationship: InputValue,
	): Promise<void> => {
		this.validateForm()

		if (!this.form.valid) {
			return
		}

		try {
			this.performer = await this.performerService.update(
				new Performer(
					this.performer.id,
					firstName as string,
					lastName as string,
					school as string,
					dob,
					gender as string,
					this.performer.tos_accepted,
					undefined,
					this.performer.schools,
					relationship as AccountRelationship,
					undefined,
				),
			)

			this.snackbar.open('Successfully updated performer', 'Okay')
		} catch (error) {
			if (error instanceof HttpError) {
				if (error.isUnprocessableEntity()) {
					this.formErrors = error.handleFormErrors(this.formErrors, 'performer')
					this.markInvalidFields()
				}
			} else {
				throw error
			}
		}
	}

	protected buildForm = () => {
		if (!this.performer) {
			return
		}

		this.form = this.formBuilder.group({
			first_name: [
				{ value: this.performer.first_name, disabled: !this.editable },
				[Validators.required],
			],
			last_name: [
				{ value: this.performer.last_name, disabled: !this.editable },
				[Validators.required],
			],
			school: [
				{ value: this.performer.school, disabled: false },
				[Validators.required],
			],
			dob: [
				{ value: this.performer.dob, disabled: !this.editable },
				[Validators.required],
			],
			gender: [
				{ value: this.performer.gender, disabled: !this.editable },
				[Validators.required],
			],
			account_relationship: [
				{
					value: this.performer.account_relationship,
					disabled: !this.editable,
				},
				[Validators.required],
			],
		})
	}

	private readonly calculateMinsEditable = () => {
		const created = new Date(this.performer.created_at)

		const hour = 1000 * 60 * 60

		const anHourAgo = new Date(Date.now() - hour)

		this.editable = created > anHourAgo

		this.minsEditable = Math.floor(
			(created.getTime() - anHourAgo.getTime()) / 1000 / 60,
		)
	}
}
